<template>
  <div class="col-md-8 mx-auto">
    <div class="card rounded shadow-lg">
      <div class="card-body">
        <div class="card-title text-center">
          <h1>Reporte de Control de entrega de documentos</h1>
        </div>
        <br>
        <form v-on:submit.prevent="searchItems(item)">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Clase de documento (*):</label>
                <select v-model="item.tcr_id" ref="tcr_id" class="form-control col-md-8" required>
                  <option v-for="i in tpocors" v-bind:value="i._id">
                    {{ i.tcr_nmbre }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Dependencia:</label>
                <select v-model="item.dep_id" class="form-control col-md-8">
                  <option v-for="i in dependencias" v-bind:value="i._id">
                    {{ i.dep_nmbre }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Fecha de Radicación (*):</label>
                <input type="date" class="form-control col-md-8" v-model="item.dcm_fcharad" required>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Hora de inicio (*):</label>
                <input type="time" class="form-control col-md-8" v-model="item.hinicio" required>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Hora de fin (*):</label>
                <input type="time" class="form-control col-md-8" v-model="item.hfin" required>
              </div>
            </div>
          </div>
          <br />
          <div v-if="message">
            <p class="alert alert-warning" role="alert">{{ message }}</p>
          </div>
          <div class="row" align="center">
            <div class="col-6">
              <button type="submit" class="btn btn-primary">Consultar</button>
            </div>
            <div class="col-6">
              <button type="text" class="btn btn-secondary" v-on:click="cancel()">Cancelar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  //import toastr from 'toastr';
  import moment from 'moment';

  export default {
    data(){
      return{
        item: {},
        message: '',
        tpocors: [],
        dependencias: [],
        debug: ''
      }
    },
    computed: {
      company() {
        return this.$store.state.company
      },
      user() {
        return this.$store.state.user
      }
    },
    created: function() {
      this.fetchTpocor();
      this.fetchDependencia();

      var now = new Date();
      var today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
      this.item.dcm_fcharad = moment(today).format('YYYY-MM-DD');
      this.debug = this.item.dcm_fcharad;

      this.item.hinicio = '00:00';
      this.item.hfin = '23:59';
    },
    mounted: function(){

    },
    methods: {
      fetchTpocor()
      {
        let uri = '/tpocors/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          var list = response.data;
          list.sort(function(a,b){
            if (a.tcr_nmbre > b.tcr_nmbre) { return 1; };
            if (a.tcr_nmbre < b.tcr_nmbre) { return -1; };
            return 0;
          });
          this.tpocors = list;
        });
      },
      fetchDependencia()
      {
        let uri = '/deps/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          var list = response.data;
          list.sort(function(a,b){
            if (a.dep_nmbre > b.dep_nmbre) { return 1; };
            if (a.dep_nmbre < b.dep_nmbre) { return -1; };
            return 0;
          });
          this.dependencias = list;
        });
      },
      searchItems(q){
        var p = {};

        var d = new Date(q.dcm_fcharad);
        if(d.getTimezoneOffset() > 0){
            d.setTime( d.getTime() + d.getTimezoneOffset()*60*1000 );
        }
        q.dcm_fcharad = d;

        p.query = q;
        p.tcr_nmbre = this.$refs.tcr_id.options[this.$refs.tcr_id.selectedIndex].text;
        this.$router.replace({ name: 'ReportControlEntrega', params: p });
      },
      cancel(){
        this.$router.replace({ name: 'Home' });
      }

    } //END METHODS
  }
  </script>

  <style>
    .warning {
      color: #ff0000;
    }

select.minimal {
  background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
}    

  </style>
